input {
  position: relative;
  outline: none;
  border: 1px solid #EDECEC;
  border-radius: 5px;
  background-color: #F2F2F2;
  color: #313131;
  font-family: var(--primary-font);
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px;
  width: calc(100% - 20px);
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
}

input::placeholder {
  color: var(--font-color-light);
  font-family: var(--font);
}
input:invalid {
  background-color: var(--color-invalid-light);
  border-color: var(--color-invalid);
}
textarea:invalid {
  background-color: var(--color-invalid-light);
  border-color: var(--color-invalid);
}
.input_invalid {
  background-color: var(--color-invalid-light);
  border-color: var(--color-invalid);
}
input[data-readonly] {
  pointer-events: none;
}
input:disabled {
  cursor: default;
}

.input-wrapper-disabled {
  label {
    color: #C0C0C2;
  }
  input {
    pointer-events: none;
    background-color: #f9f9f9;
    border-color: #f9f9f9;
    cursor: default;
  }
}

textarea {
  position: relative;
  outline: none;
  border: 1px solid #EDECEC;
  border-radius: 5px;
  background-color: white;
  color: #313131;
  font-family: var(--primary-font);
  font-size: 12px;
  font-weight: 500;
  padding: 8px 10px;
  width: calc(100% - 20px);
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  height: 80px;
  resize: none;
}

textarea::placeholder {
  color: var(--font-color-light);
  font-family: var(--font);
}
// textarea:invalid {
//   background-color: var(--color-invalid-light);
//   border-color: var(--color-invalid);
// }
.textarea_invalid {
  background-color: var(--color-invalid-light);
  border-color: var(--color-invalid);
}
textarea[data-readonly] {
  pointer-events: none;
}
textarea:disabled {
  cursor: default;
}
// .input_error {
//   border-color: red;
// }

.input_wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  align-items: flex-start;
  width: -webkit-fill-available;
}

.input_dropdown {
  padding: 0;
}

.inputfield_wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-color-border);
  padding: 5px;
  background-color: var(--input-color-background);
  width: -webkit-fill-available;
  width: -moz-available;
}
// .inputfield_wrapper:focus-within {
//   border-color: var(--input-color-focus);
// }
// .inputfield_wrapper_invalid {
//   border-color: var(--input-color-invalid)
// }

.input_valid_indicator {
  position: relative;
  top: calc(50% - 6px);
  left: calc(100% - 12px - 10px);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--color-notify-3);
}
.input_valid_indicator_invalid {
  background-color: var(--color-notify-1);
}


.inputfield_search {
  background-color: #F2F2F2;
  border-radius: 5px;
  border: 1px solid #EDECEC;
  width: calc(100% - 55px);
  padding: 8px 20px 8px 35px;
  background-image: url('../assets/lupe.svg');
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 12px center;
}
.inputfield_search::placeholder {
  color: #C0C0C2;
}


//thread message input
.message_box_wrapper {
  position: relative;
  width: 100%;
}
.message_box {
  background-color: var(--color-interface-4);
  border: 1px solid var(--color-interface-4)2;
  border-radius: 6px;
  padding: 10px;
  font-family: var(--font);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #2C2C2C;
  width: calc(100% - 20px);
  resize: none;
  resize: none;
  outline: none;
  margin-bottom: 10px;
}

.data_box_inner_flex {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 5px;
}


@media (max-width: 600px) {
  input {
    position: relative;
    outline: none;
    border: 1px solid #EDECEC;
    border-radius: 5px;
    background-color: white;
    color: #313131;
    font-family: var(--primary-font);
    font-size: 12px;
    font-weight: 500;
    padding: 8px 10px;
    width: calc(100% - 20px);
  }
  .inputfield_search {
    width: calc(100% - 35px);
    padding: 4px 10px 4px 25px;
    background-position: 7px center;
  }
}

.data_box, .data_box_array {
  position: relative;
  background-color: #ffffff;
  border: 1px solid var(--border-color);
  border-radius: 13px;
  padding: 0px 0;
}
.data_box h2 {
  padding: 0;
  margin-bottom: 6px;
}
.data_box_array h2 {
  padding: 15px 20px;
  padding-bottom: 0;
  width: calc(100% - 40px);
  margin-bottom: 0px;
}
.data_box_package {
  position: relative;
  padding: 20px 25px 25px 25px;
  width: calc(100% - 50px);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 10px;
}
.data_box_package_last {
  border: none;
}
.data_box_package_inner_flex {
  display: flex;
  align-items: center;
  column-gap: 5px;
  row-gap: 5px;
  flex-wrap: wrap;
}

.data_box_disabled {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 20px 5px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 10px);
  backdrop-filter: blur(5px);
  background-color: rgb(159 159 159 / 16%);
  z-index: 100;
  border-radius: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}
.data_box_disabled_faid {
  opacity: 0;
}
.data_box_disabled::before {
  content: "";
  position: absolute;
  border-radius: 13px;
  border: 2px dashed #64a3dabb;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
}

.data_box_paragraph_projects, .data_box_paragraph_ticket {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 5px;
  padding: 10px 20px;
  border-bottom: 1px solid var(--border-color);
  transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}
.data_box_paragraph_projects, .data_box_paragraph_ticket {
  cursor: pointer;
  label {
    cursor: pointer;
  }
}
.data_box_paragraph_projects:hover, .data_box_paragraph_ticket:hover {
  background-color: var(--hover-color);
}
.data_box_paragraph_ticket {
  display: grid;
  grid-template-columns: 5% 93%;
  column-gap: 10px;
  text-decoration: none;
}


.lbl_customer_data_box_data {
  font-size: 12px;
  font-weight: 500;
  word-break: break-word;
  color: var(--font-color);
}
.lbl_customer_data_box_data p {
  margin: 0;
}


.data_box_single_title {
  padding: 20px 25px;
  width: calc(100% - 50px);
}



@media (max-width: 600px) {
  .data_box_package {
    position: relative;
    padding: 12px;
    width: calc(100% - 24px);
    row-gap: 5px;
  }
}

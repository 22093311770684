button {
  margin: 0;
  border: none;
  font-family: var(--font);
  background-color: transparent;
  padding: 6px 15px;
  font-size: 14px;
  cursor: pointer;
}

.button_icon_lbl {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 6px 10px;
  background-color: transparent;
  color: var(--color-2);
  font-size: 12px;
  width: fit-content;
  height: fit-content;
}
.button_icon_lbl img {
  width: 10px;
}

.button_icon_lbl_dark {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 6px 0px;
  background-color: transparent;
  color: var(--font-color);
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  height: fit-content;
  text-align: start;
}
.button_icon_lbl_dark img {
  width: 10px;
}

.button_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 34px;
  height: 34px;
  border-radius: 50%;
}
.button_icon:hover {
  background-color: var(--color-hover);
}
.button_icon img {
  width: 12px;
  transform: rotate(-90deg);
}


.btn_submit {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  color: white;
  font-family: var(--font);
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  background-color: var(--color-interface-6);
  border: 2px solid transparent;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
}
.btn_submit:hover {
  background-color: var(--color-interface-6-light);
}
.btn_submit_inactive {
  cursor: default;
  pointer-events: none;
  color: white;
  background-color: var(--color-interface-1);
}

.btn_cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 15px;
  border-radius: 4px;
  color: #B4B4B4;
  font-family: var(--font);
  font-weight: 500;
  text-align: center;
  font-size: 14px;
  background-color: #b4b4b442;
  border: 2px solid transparent;
  cursor: pointer;
  width: -webkit-fill-available;
  width: -moz-available;
}
.btn_cancel:hover {
  color: white;
  background-color: #B4B4B4;
}

.btn_action_wrapper {
  display: flex;
  align-items: center;
  justify-self: center;
  color: var(--primary-font-color-3);
  cursor: pointer;
}
.btn_action_icon {
  width: 7px;
  margin-right: 5px;
}
.btn_label {
  font-family: var(--font);
  color: var(--color-tag-5);
  background-color: transparent;
  font-size: 12px;
  margin: 10px 0;
  padding: 0;
  cursor: pointer;
}
.btn_label_icon {
  width: 8px;
  padding: 0 5px;
}

.btn_flex_box {
  display: flex;
  align-items: center;
  background-color: transparent;
  margin: 10px 0;
  padding: 0;
  cursor: pointer;
}
.btn_flex_box > img {
  width: 8px;
  padding: 0 5px;
}
.btn_flex_box > label {
  font-size: 12px;
  font-family: var(--font);
  color: var(--color-tag-5);
  cursor: pointer;
}

.btn-tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 6px 15px;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  font-family: var(--font);
  font-weight: 300;
  white-space: nowrap;
  border: solid 1px var(--border-color);
  color: var(--font-color);
  cursor: pointer;
}
.btn-tab-green {
  border: solid 1px var(--color-tag-6);
  color: var(--color-tag-6);
  background-color: var(--color-tag-6-light);
}
.btn-tab-red {
  border: solid 1px var(--color-tag-3);
  color: var(--color-tag-3);
  background-color: var(--color-tag-3-light);
}
.btn-tab-orange {
  border: solid 1px var(--color-tag-2);
  color: var(--color-tag-2);
  background-color: var(--color-tag-2-light);
}
.btn-tab-blue {
  border: solid 1px var(--color-tag-5);
  color: var(--color-tag-5);
  background-color: var(--color-tag-5-light);
}

.btn_tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  padding: 6px 15px;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  font-family: var(--font);
  font-weight: 300;
  white-space: nowrap;
  border: solid 1px transparent;
  cursor: pointer;
}
.btn_tab_blue {
  border: solid 1px var(--color-tag-5);
  color: #ffffff;
  background-color: var(--color-tag-5);
}
.btn_tab_gray {
  border: solid 1px var(--color-tag-4);
  color: #ffffff;
  background-color: var(--color-tag-4);
}
.btn_tab_blue_light {
  border: solid 1px var(--color-tag-5-light);
  color: var(--color-tag-5);
  background-color: var(--color-tag-5-light);
}
.btn_tab_blue_light:hover {
  border-color: var(--color-tag-5);
  background-color: var(--color-tag-5);
  color: white;
}
.btn_tab_disabled {
  background-color: #dadada;
  color: var(--font-color-light);
  border-color: #dadada;
}
.btn_tab:hover label {
  border-color: white;
  background-color: var(--color-tag-5);
  color: white;
  cursor: pointer;
}
// .btn_tab_blue:hover {
//   border-color: var(--color-tag-5-light);
//   background-color: var(--color-tag-5-light);
// }


.btn_add_field {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  background-color: transparent;
  border-radius: 12px;
  padding: 35px 10px;
  color: #64a3da;
  font-size: 12px;
  cursor: pointer;
}
.btn_add_field::before {
  content: "";
  border-radius: 12px;
  position: absolute;
  border: 2px dashed #64a3dabb;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
}
.icon_add {
  width: 8px;
}

.icon_contact_remove {
  padding: 10px;
  width: 12px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}
.icon_contact_remove:hover {
  background-color: var(--hover-color);
}



.btn_box_remove {
  display: flex;
  align-items: center;
  column-gap: 2px;
  padding: 5px;
  border: solid 1px var(--font-color-light);
  border-radius: 4px;
  border-color: transparent;
  background-color: var(--font-color-light);
  color: var(--font-color);
  font-size: 9px;
}
.btn_box_remove img {
  width: 6px;
  height: 6px;
  padding: 2px;
  cursor: pointer;
}
.btn_box_add {
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 5px 5px 5px 14px;
  border: solid 1px var(--color-tag-5-light);
  border-radius: 4px;
  border-color: transparent;
  color: var(--color-tag-5);
  background-color: var(--color-tag-5-light);
  font-size: 9px;
  background-image: url('../assets/addelement.svg');
  background-size: 6px;
  background-repeat: no-repeat;
  background-position: 5px center;
  cursor: pointer;
}


.hover_info_box {
  position: relative;
}
.hover_info_box img {
  width: 14px;
  cursor: pointer;
}
.hover_info_box label {
  visibility: hidden;
  position: absolute;
  top: 0px;
  left: 0;
  padding: 15px;
  border: 1px solid var(--color-1);
  border-radius: 8px;
  background-color: white;
  color: var(--color-1);
  font-size: 11px;
  width: 180px;
  z-index: 1000;
}
.hover_info_box:hover {
  label {
    visibility: visible;
  }
}

@media (max-width: 600px) {
  .btn_submit {
    padding: 15px;
    border-radius: 5px;
    font-weight: 500;
    font-size: 20px;
    border: 2px solid transparent;
  }
  .btn_tab {
    column-gap: 5px;
    font-size: 14px;
  }
}

.action_hover_object {
  display: flex;
  margin-bottom: 5px;
}
.action_hover_object > img {
  cursor: pointer;
}
.action_hover_object > label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  word-break: break-all;
}
.action_hover_object:hover .action_hover_icon {
  visibility: visible;
  opacity: 1;
  width: 13px;
  margin-right: 8px;
  cursor: pointer;
}
.action_hover_icon {
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
}
.action_hover_inner_flex {
  display: flex;
  width: 100%;
  cursor: pointer;
}
.action_hover_inner_flex > img {
  cursor: pointer;
}
.action_hover_inner_flex > label {
  cursor: pointer;
}


.value_hover_password {
  display: flex;
  width: 100%;
  align-items: center;
}

.value_hover_password > label {
  width: -webkit-fill-available;
}

.icon_eye {
  padding: 5px;
  height: 12px;
  width: 12px;
  justify-self: flex-end;
  cursor: pointer;
}

.value_hover_password > img {
  cursor: pointer;
}
.value_hover_password > label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  word-break: break-all;
}
.value_hover_password:hover .action_hover_icon {
  visibility: visible;
  opacity: 1;
  width: 11px;
  margin-right: 8px;
  cursor: pointer;
}

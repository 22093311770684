.lbl-tag {
  width: fit-content;
  font-size: 11px !important;
  font-family: var(--font);
  padding: 2px 4px;
  border-radius: 3px;
}
.tag-green {
  color: #2DA44E !important;
  background-color: #2da44f4a;
}
.tag-blue {
  color: #2f2da6 !important;
  background-color: #2f2da686;
}
.tag-red {
  color: #DB5844 !important;
  background-color: #db584479;
}

.user_img_container {
  position: relative;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: 1px solid #E2DEDE;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  background-color: var(--color-tag-5);
}
.user_img {
  object-fit: cover;
}
.user_img_placeholder {
  object-fit: none;
}

.img-go-to-web {
  width: 12px;
  cursor: pointer;
}

@import '../src/stylesheets/input.scss';
@import '../src/stylesheets/dropdown.scss';
@import '../src/stylesheets/label.scss';
@import '../src/stylesheets/button.scss';
@import '../src/stylesheets/slider.scss';
@import '../src/stylesheets/image.scss';
@import '../src/stylesheets/databoxes.scss';
@import '../src/stylesheets/checkbox.scss';
@import '../src/stylesheets/dialog.scss';
@import '../src/stylesheets/actions.scss';
@import '../src/stylesheets/tickets.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --color-1: #3B88CC;
  --color-2: #64a3da;
  --color-hover: #c0c0c03e;

  //interface
  --color-interface-1: #3B3638;
  --color-interface-2: #B4B4B4;
  --color-interface-3: #E2DEDE;
  --color-interface-4: #F7F7F7;
  --color-interface-5: #FFFFFF;
  --color-interface-6: #FF2E2E;
  --color-interface-6-light: #ff2e2ecf;
  --color-interface-7: #3B88CC;
  //tags
  --color-tag-1: #3B3638;
  --color-tag-1-light: #3b363829;
  --color-tag-2: #E89712;
  --color-tag-2-light: #ffdba0;
  --color-tag-3: #DB5844;
  --color-tag-3-light: #db584479;
  --color-tag-4: #B4B4B4;
  --color-tag-4-light: #B4B4B4;
  --color-tag-5: #64a3da;
  --color-tag-5-light: #bad9f5;
  --color-tag-6: #2DA44E;
  --color-tag-6-light: #2da44f4a;
  --color-tag-7: #2f2da6;
  --color-tag-7-light: #2f2da686;


  //notifications
  --color-notify-1: #FF2E2E;
  --color-notify-1-light: #FFD5D5;;
  --color-notify-2: #F6B852;
  --color-notify-3: #2DA44E;
  --color-notify-3-light: #D3EBD9;
  --color-notfiy-4: #3B88CC;

  //button
  --color-btn-submit: #3B88CC;
  --color-btn-submit-light: #64a3da5e;

  //font
  --font: 'Montserrat', sans-serif;
  --font-color: #2a2a2a;
  --font-color-light: #2a2a2a5a;

  //other
  --border-color: #E2DEDE;
  --hover-color: #c0c0c03e;
  --color-invalid: #FF2E2E;
  --color-invalid-light: #ff2e2e1c;


  //values
  --menu-header-height: 65px;
  --menu-header-height-Phone: 60px;

  --hexagon-color-1: #0c0c0c;
  --hexagon-color-2: #12121267;

  --color-shadow-rgb: 20, 45, 82;
  --shadow-opacity-1: .01;
  --shadow-opacity-2: .02;
  --shadow-opacity-3: .03;
  --shadow-opacity-4: .04;
  --shadow-opacity-5: .05;

  //phone
  --vh: calc(calc(var(--calc-vh) * 100));
  --vw: calc(calc(var(--calc-vw) * 100));
}

html, body {
  margin: 0;
  padding: 0;
  background-color: var(--color-interface-4);
  overflow: hidden;
  min-width: 100vw;
  min-height: var(--vh);
  height: var(--vh);
  font-family: var(--font);
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
}

button {
  margin: 0;
  padding: 0;
}

//traffic light
.date_traffic_light {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  margin-left: 8px;
}
.code_green {
    background-color: var(--color-notify-3);
}
.code_orange {
    background-color: var(--color-notify-2);
}
.code_red {
    background-color: var(--color-notify-1);
}

//Srolling
::-webkit-scrollbar {
  width: 6px;
  height: 2px;
  background: transparent;
  cursor: pointer;
}
::-webkit-scrollbar-track {
  background: transparent;
  height: 100px;
}
::-webkit-scrollbar-thumb {
  background: #39393940;
}
::-webkit-scrollbar-thumb:hover {
  background: #39393940;
}
::-webkit-scrollbar-corner{
  background: transparent;
}

a {
  text-decoration: none;
}

.flex_slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.slider_container {
  width: 30px;
  height: 15px;
  background-color: transparent;
  margin-left: 5px;
  margin-right: 10px;
}
.slider_still {
  height: 15px;
  width: 32px;
  border: 1px solid var(--font-color);
  border-radius: 15px;
  background-color: var(--color-tag-5-light);
  border-color: var(--color-tag-5);
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  cursor: pointer;
}
.slider_still_on {
  border-color: var(--font-color-light);;
  background-color: transparent;
}
.slider_move {
  height: 13px;
  width: 13px;
  border-radius: 22px;
  background-color: var(--color-tag-5);
  transition: 0.2s;
  cursor: pointer;
}
.slider_off {
  transform: translate(2px, -15px);
  background-color: var(--font-color-light);
}
.slider_on {
  transform: translate(19px, -15px);
  background-color: var(--color-tag-5);
}

.dialog_view {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: #313131d7;
  z-index: 2000;
  padding: 0;
}

.dialog_window {
  min-width: 500px;
  min-height: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  max-width: 60vh;
  width: fit-content;
  height: fit-content;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
}

.dialog_window_btn_close {
  position: absolute;
  top: -35px;
  left: 100%;
  color: white;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s;
  transition: 0.1s;
  -webkit-transition: 0.1s;
  -moz-transition: 0.1s;
  -o-transition: 0.1s;
  cursor: pointer;
  background-color: transparent;
}
.dialog_window_btn_close img {
  width: 15px;
  height: 15px;
}
.dialog_window_btn_close:hover {
  background-color: #a8a8a860;
}

.dialog_window_header {
  width: calc(100% - 50px);
  display: flex;
  padding: 25px 25px 0px 25px;
}
.dialog_window_header h2 {
  font-size: 20px;
}

.dialog_window_body {
  width: calc(100% - 50px);
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 25px;
}

.dialog_window_btn_submit {
  margin-top: 20px;
  width: 100%;
  padding: 15px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--color-btn-submit);
  background-color: var(--color-btn-submit-light);
  font-size: 14px;
  cursor: pointer;
  transition: 0.1s ease-in;
  -moz-transition: 0.1s ease-in;
  -ms-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  -webkit-transition: 0.1s ease-in;
  transform: translate(0,1px);
}
.dialog_window_btn_submit:hover {
  color: white;
  background-color: var(--color-btn-submit);
}

@media (max-width: 980px) {
  .dialog_window {
    min-width: 300px;
  }
}
.dropdown_wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.dropdown_wrapper_disabled {
  pointer-events: none;
  cursor: default;
}

.dropdown_icon {
  position: absolute;
  top: calc(50% - 4px);
  left: calc(100% - 8px - 15px);
  height: 8px;
  width: 8px;
  transition: 0.2s ease-in;
  -moz-transition: 0.2s ease-in;
  -ms-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  -webkit-transition: 0.2s ease-in;
  cursor: pointer;
}
.dropdown_icon_open {
  transform: rotate(180deg);
}

.dropdown_value_container {
  position: absolute;
  background-color: white;
  border: 1px solid #EDECEC;
  border-top-color: transparent;
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 8px 0px;
  top: 28px;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-x: hidden;
  z-index: 100;
}
/* .dropdown_value_container_filter {
  background-color: var(--color-secondary);
  border-color: var(--color-primary);
  border-top: none;
} */

.dropdown_value_container_scroll_wrapper {
  width: 100%;
  max-height: 180px;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: thin !important;
  scroll-behavior: smooth;
}
.dropdown_element {
  display: flex;
  align-items: flex-start;
  padding: 8px 10px;
  cursor: pointer;
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
  -moz-transition: 0.1s ease-out;
  -o-transition: 0.1s ease-out;
}
.dropdown_element:hover {
  background-color: var(--hover-color);
}
.dropdown_element_active {
  background-color: var(--hover-color);
}



// .dropdown_wrapper {
//   position: relative;
//   background-color: #FFFFFF;
//   border-radius: 5px;
//   padding: 10px 10px;
//   border: 1px solid #EDECEC;
//   transition: 0.2s ease-in;
//   -moz-transition: 0.2s ease-in;
//   -ms-transition: 0.2s ease-in;
//   -o-transition: 0.2s ease-in;
//   -webkit-transition: 0.2s ease-in;
//   width: calc(100% - 22px);
//   // width: -webkit-fill-available;
//   // width: -moz-available;
//   cursor: pointer;
// }
// .dropdown_wrapper > label {
//   cursor: unset;
// }
// .dropdown_wrapper > input {
//   padding: 0;
//   cursor: unset;
// }
// .dropdown_wrapper_active {
//   border-bottom-left-radius: 0;
//   border-bottom-right-radius: 0;
//   border-bottom-color: transparent;
// }

// .dropdown_wrapper_input {
//   position: relative;
//   background-color: #FFFFFF;
//   border-radius: 5px;
//   padding: 10px 10px;
//   border: 1px solid #EDECEC;
//   transition: 0.2s ease-in;
//   -moz-transition: 0.2s ease-in;
//   -ms-transition: 0.2s ease-in;
//   -o-transition: 0.2s ease-in;
//   -webkit-transition: 0.2s ease-in;
//   width: calc(100% - 22px);
// }
// .dropdown_wrapper_input > input {
//   padding: 0;
// }

// .dropdown_btn {
//   border-radius: 5px;
//   border: 2px solid transparent;
//   background-color: transparent;
//   transition: 0.2s ease-in;
//   -moz-transition: 0.2s ease-in;
//   -ms-transition: 0.2s ease-in;
//   -o-transition: 0.2s ease-in;
//   -webkit-transition: 0.2s ease-in;
//   width: -webkit-fill-available;
//   width: -moz-available;
//   // padding-right: 60px;
//   white-space: nowrap;
// }
// .dropdown_btn_error {
//   border-color: #FFAC64;
// }
// .dropdown_icon {
//   position: absolute;
//   top: calc(50% - 4px);
//   left: calc(100% - 8px - 15px);
//   height: 8px;
//   width: 8px;
//   transition: 0.2s ease-in;
//   -moz-transition: 0.2s ease-in;
//   -ms-transition: 0.2s ease-in;
//   -o-transition: 0.2s ease-in;
//   -webkit-transition: 0.2s ease-in;
// }
// .dropdown_icon_rotate {
//   transform: rotate(180deg);
// }

// .dropdown_value_container {
//   position: absolute;
//   background-color: white;
//   // box-shadow: 3px 3px 13px #4c4c4c66;
//   border: 1px solid #EDECEC;
//   border-top-color: transparent;
//   border-radius: 5px;
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
//   padding: 10px 0px;
//   top: 25px;
//   left: -1px;
//   width: 100%;
//   max-height: 200px;
//   overflow-x: hidden;
//   z-index: 100;
// }

// .dropdown_value_container_scroll_wrapper {
//   width: calc(100% - 0px);
//   max-height: 180px;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   scrollbar-width: thin !important;
//   scroll-behavior: smooth;
// }
// .dropdown_element {
//   display: flex;
//   align-items: flex-start;
//   padding: 10px 12px;
//   cursor: pointer;
//   transition: 0.1s ease-out;
//   -webkit-transition: 0.1s ease-out;
//   -moz-transition: 0.1s ease-out;
//   -o-transition: 0.1s ease-out;
// }
// .dropdown_element:hover {
//   color: var(--color-tag-5);
//   background-color: var(--color-tag-5-light);
// }
// .dropdown_element_active {
//   color: var(--color-tag-5);
//   background-color: var(--color-tag-5-light);
// }

// .dropdown_element_icon {
//   width: 18px;
//   height: 18px;
//   margin-right: 10px;
// }

// .dropdown_invalid {
//   border-color: var(--color-notify-1)
// }

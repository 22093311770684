.checkbox_wrapper {
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: max-content;
  cursor: pointer;
}
.checkbox_wrapper > label {
  cursor: pointer;
}

input[type="checkbox"] {
  border: 2px solid #D5D5D5;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  padding: 1px;
}

input[type="checkbox"]:checked {
  content: url('../assets/checkboxicon.svg');
  background-color: var(--color-btn-submit);
  border-color: var(--color-btn-submit);
}

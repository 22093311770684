@import '../../src/stylesheets/breakpoint.scss';

h1 {
  display: flex;
  justify-content: center;
  font-weight: lighter;
  font-size: 60px;
  font-family: var(--font);
  font-weight: 500;
  font-style: normal;
  color: var(--font-color);
  margin-bottom: 0px;
  margin-top: 0px;
}
h2 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
h3 {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}



label {
  color: var(--font-color);
  font-family: var(--font);
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.span {
  word-break: break-word;
}

.lbl_for {
  color: #858585;
  font-family: var(--primary-font);
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.lbl_light {
  color: var(--font-color-light);
}
.lbl_bold {
  color: var(--font-color);
  font-size: 12px;
  font-weight: 600;
}




.lbl_dropdown_small {
  font-weight: 600;
  font-size: 12px;
}
.lbl_input {
  font-size: 14px;
  font-weight: 400;
}

.lbl_color_small {
  font-size: 11px;
  font-weight: 400;
  color: var(--color-tag-5);
}

.lbl_regular_11 {
  font-size: 11px;
  font-weight: 400;
}
.lbl_regular_12 {
  font-size: 12px;
  font-weight: 500;
}
.lbl_regular_14 {
  font-size: 14px;
  font-weight: 500;
  // white-space: nowrap;
}
.lbl_bold_20 {
  font-size: 20px;
  font-weight: 600;
}
.lbl_bold_18 {
  font-size: 18px;
  font-weight: 600;
}
.lbl_light_11 {
  font-size: 12px;
  font-weight: 400;
}
.lbl_light_12 {
  font-size: 12px;
  font-weight: 400;
}

.lbl_header_menu {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}



.lbl_box {
  padding: 3px 5px;
  border: solid 1px transparent;
  border-radius: 4px;
  color: #b4b4b4b8;
  background-color: transparent;
  font-size: 11px;
  width: fit-content;
  height: fit-content;
  cursor: default;
}
.lbl_box_trans {
  border-color: var(--font-color-light);
  background-color: transparent;
  color: var(--font-color);
}
.lbl_box_gray {
  border-color: transparent;
  background-color: var(--font-color-light);
  color: var(--font-color);
}
.lbl_box_green {
  border-color: transparent;
  background-color: var(--color-tag-6-light);
  color: var(--color-tag-6);
}
.lbl_box_red {
  border-color: transparent;
  background-color: var(--color-tag-3-light);
  color: var(--color-tag-3);
}
.lbl_box_violet {
  border-color: transparent;
  background-color: var(--color-tag-7-light);
  color: var(--color-tag-7);
}
.lbl_box_yellow {
  border-color: transparent;
  background-color: var(--color-tag-2-light);
  color: var(--color-tag-2);
}
.lbl_box_blue {
  border-color: transparent;
  color: #ffffff;
  background-color: var(--color-tag-5);
}
.lbl_box_blue_light {
  border-color: transparent;
  color: var(--color-tag-5);
  background-color: var(--color-tag-5-light);
}
.lbl_box_btn_icon {
  width: 8px;
  cursor: pointer;
}

.lbl_error {
  color: var(--color-notify-1);
}

.lbl_color_box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  height: fit-content;
}
.lbl_color_box_red {
  color: var(--color-tag-3);
  background-color: rgba(255, 46, 46, 0.2666666667);
}
.lbl_color_box_green {
  color: var(--color-tag-6);
  background-color: var(--color-tag-6-light);
}

.lbl_counter {
  position: absolute;
  top: calc(0% - 10px);
  left: calc(100% - 12px);
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-tag-5);
  border-radius: 50%;
  color: var(--color-tag-5);
  background-color:  var(--color-tag-5-light);
}

.lbl_message {
  display: block;
}


@media only screen and (max-width: $breakpoint-desktop-middle-2) {
  .lbl_regular_14 {
    font-size: 12px;
  }
}


@media (max-width: 600px) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 12px;
  }
}
